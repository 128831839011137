<template>
  <div class="admin" :style="{ minHeight: mainHeight + 'px' }">
    <el-container>
      <el-header class="qjc-c-clearfix header-bg" height="60px">

        <div class="header-left">
          <div class="logo-bg custom-hiden-1000-down" @click="handleToHome">
            <img class="logo" src="@/assets/images/logo-new.png" alt="logo">
          </div>
          <el-image class="screen-img" :src="require('@/assets/images/back-new.png')" @click="handleRefresh"></el-image>
          <el-image class="screen-img" :src="require('@/assets/images/screen.png')"
            @click="handleEnterFullscreen"></el-image>


        </div>
        <div class="user">

          <div class="user-new">
            <el-dropdown @command="handleChangeTheme">
              <span class="el-dropdown-link">
                <i class="el-icon-s-tools"></i>
                <span class="user-new-title">{{ $t('table.t29') }}</span>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item disabled>{{ $t('table.t31') }}</el-dropdown-item>
                <el-dropdown-item command="themeWhite">
                  <el-radio v-model="themeType" label="1">{{ $t('table.t32') }}</el-radio>
                </el-dropdown-item>
                <el-dropdown-item command="themeBlack">
                  <el-radio v-model="themeType" label="2">{{ $t('table.t33') }}</el-radio>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="user-new" @click="handleToNews">
            <span>
              <i class="el-icon-message-solid"></i>
              <span class="user-new-title">{{ $t('table.t28') }}</span>
            </span>
            <div class="red-box" v-if="unread > 0">{{ unread > 99 ? '99+' : unread }} </div>
          </div>
          <div class="user-new">
            <el-image class="user-new-img" :src="require('@/assets/images/avatar.png')"></el-image>
            <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link">
                {{ userInfo.username ? userInfo.username : '--' }}<i class="el-icon-caret-bottom el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="user">{{ $t('table.t47') }}</el-dropdown-item>
                <el-dropdown-item v-if="userInfo.id == 1" command="clear">清除缓存</el-dropdown-item>
                <el-dropdown-item command="loginOut">
                  <div class="logout">
                    {{ $t('table.t30') }}
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </el-header>
      <el-container class="main-container">
        <el-aside width="270px" class="el-aside-nav qjc-bg-fff">
          <Aside></Aside>
        </el-aside>

        <el-main class="" :style="{ height: mainHeight - 60 + 'px' }">
          <div v-if="$route.name == 'mainNews' && isRouterAlive" class="main-news">
            <div class="update">
              <div class="wrap">
                <div class="new-box">
                  <div class="list-wrap">
                    <div class="news-list" :style="{ minHeight: mainHeight - 180 + 'px' }" v-if="list.length > 0">
                      <div class="new-item" v-for="item in list" @click="handleLoadFind(item.id)" :key="item.id">
                        <div class="new-item-header">
                          <div class="new-item-title">{{ item.name }}</div>
                          <div class="new-item-times">{{ item.created_at }}</div>
                        </div>
                        <div class="qjc-flex new-item-bot">
                          <div class="new-item-content">{{ item.title }}</div>
                          <div class="new-item-read" :class="item.is_read == '2' ? 'readed' : 'not-read'">{{
                            item.is_read == '1' ? 'unread' : item.is_read == '2' ? 'read' : ''}}</div>
                        </div>
                      </div>
                      <el-pagination class="pagination qjc-texta-c" layout="prev, pager, next" hide-on-single-page
                        :current-page.sync="pager.page" :page-count="pager.pagecount" :page-size="pager.pagesize"
                        :total="pager.count" @current-change="pageChange">
                      </el-pagination>
                    </div>
                    <div class="no-data" v-if="list.length <= 0">
                      <div class="">
                        <el-image class="empty-img" :src="require('@/assets/images/empty-new.png')"></el-image>
                        <div class="empty-text">{{ $t('table.t52') }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="new-detail" v-if="JSON.stringify(newData) != '{}'">
                    <div class="new-detail-header">
                      <div class="new-detail-avatar">{{ newData.from.substr(-2) }}</div>
                      <div class="new-detail-header-info">
                        <div class="qjc-flex new-detail-cell">
                          <div class="new-detail-from">{{ newData.from }}</div>
                          <div class="new-detail-time">{{ newData.created_at }}</div>
                        </div>
                        <div class="new-detail-to qjc-flex">
                          <span class="new-detail-to-text">recipient：</span>
                          <span class="new-detail-to-title">{{ newData.to }}</span>
                        </div>
                        <div class="new-detail-theme">
                          <div class="new-detail-theme-text">{{ newData.title }}</div>
                          <div class="new-detail-success">sent successfully</div>
                        </div>
                      </div>
                    </div>
                    <div class="new-detail-content">
                      <div class="new-detail-content-text" v-html="newData.content"></div>
                    </div>
                  </div>
                  <div class="new-detail-no" v-if="JSON.stringify(newData) == '{}' && list.length > 0">
                    <div class="new-detail-no-box">
                      <el-image class="new-no-img" :src="require('@/assets/images/empty-new.png')"></el-image>
                      <div class="new-no-title">No news selected at this time</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <router-view v-else></router-view> -->
          <router-view v-if="$route.name != 'mainNews' && isRouterAlive"></router-view>

        </el-main>
        <el-dialog :title="$t('table.t47')" :visible.sync="isUserInfo" width="50%" :show-close="true"
          @close="handleUserClose">
          <div class="header-popup">
            <div class="info-cell">
              <div class="info-cell-label">{{ $t('table.t48') }}</div>
              <el-input class="password-input" v-model="userInfo.username" :readonly="true"></el-input>
            </div>
            <div class="info-cell">
              <div class="info-cell-label">{{ $t('table.t49') }}</div>
              <el-input type="password" show-password class="password-input" v-model.trim="oldPassword"></el-input>
            </div>
            <div class="info-cell">
              <div class="info-cell-label">{{ $t('table.t50') }}</div>
              <el-input type="password" show-password class="password-input" v-model.trim="newPassword"></el-input>
            </div>
            <div class="info-cell">
              <div class="info-cell-label">{{ $t('table.t51') }}</div>
              <el-input type="password" show-password class="password-input" v-model.trim="affirmPassword"></el-input>
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="handleUserClose">{{ $t('goods.t18') }}</el-button>
            <el-button @click="handleUserSubmit">{{ $t('goods.t17') }}</el-button>
          </span>
        </el-dialog>

      </el-container>
    </el-container>

  </div>
</template>

<script>
import { clearCache, adminProfile, messageNews } from '@/utils/axios'
import Aside from '@/components/Aside/index'

export default {
  name: 'admin',
  data () {
    return {
      mainHeight: (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight),// 容器最小高度(去除header高度)
      isUserInfo: false, // 是否显示修改密码弹窗
      oldPassword: '', // 原密码
      newPassword: '', // 新密码
      affirmPassword: '', // 确认新密码
      userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : '',
      isTheme: false,
      isScreen: false,
      themeType: localStorage.getItem('themeType') ? localStorage.getItem('themeType') : '1',
      list: [],
      pager: {
        count: 0,
        page: 1,// 默认第一页
        pagecount: 0,
        pagesize: 8//默认一页8条数据
      },
      unread: 0,
      newData: {},
      isRouterAlive: true,
      language: sessionStorage.getItem('theLanguage') ? sessionStorage.getItem('theLanguage') : 'zh'
    }
  },
  components: {
    Aside
  },
  provide () {
    return {
      handleRefresh: this.handleRefresh
    }
  },
  mounted () {
    // 监听判断是否为刷新操作
    window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
    if (this.$route.fullPath == '/mainNews') {
      this.handleLoadList()
    }
    this.handleNewRed();
    window.onresize = () => {
      return (() => {
        this.mainHeight = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight)
      })();
    };
  },
  destroyed () {
    // 销毁监听
    window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
  },
  watch: {
    // 根据路由变化储存当前路由 防止刷新返回首页
    $route () {
      if (!localStorage.getItem('access_token')) {
        this.$store.dispatch('logout')
        return
      }
      if (this.$route.fullPath == '/mainNews') {
        this.handleLoadList()
      } else {
        this.handleNewRed();
      }
      if (localStorage.getItem('access_token') && this.$route.fullPath != '/login') {
        sessionStorage.setItem('historyRoute', this.$route.fullPath)
      }
    },
  },
  methods: {
    handleChangeSelect () {
      sessionStorage.setItem("theLanguage", this.language)
      location.reload()// 刷新当前页面
    },
    // 刷新存储当前路由
    beforeunloadHandler (e) {
      if (localStorage.getItem('access_token') && this.$route.fullPath != '/login') {
        sessionStorage.setItem('historyRoute', this.$route.fullPath)
      }
    },
    handleToHome () {
      if (this.$route.name != 'home') {
        this.$router.push('/')
      }
    },
    handleBack () {
      this.$router.back(-1)
    },
    handleRefresh () {
      // location.reload()
      this.isRouterAlive = false; //先关闭，
      this.$nextTick(function () {
        this.isRouterAlive = true; //再打开
      });
    },
    pageChange (page) {
      this.pager.page = page;
      this.handleLoadList();
    },
    handleNewRed () {
      messageNews({
        act: 'tips'
      }).then((res) => {
        if (res.code == 200) {
          this.unread = res.data.unread
        }
      })
    },
    handleLoadFind (id) {
      messageNews({ act: 'query', id: id }).then((res) => {
        if (res.code == 200) {
          let data = JSON.parse(window.atob(res.data))
          console.log(data)
          this.newData = data
        } else {
          this.$myAlert({
            type: 'fail',
            title: res.msg
          });
        }
      })
    },
    handleLoadList () {
      messageNews({
        page: this.pager.page,
        pagesize: this.pager.pagesize
      }).then((res) => {
        if (res.code == 200) {
          this.list = res.data.data

          var pager = res.data.pager;
          for (var key in pager) {
            pager[key] = parseInt(pager[key]);
          }
          this.pager = pager;
          this.handleNewRed();
        }
      })
    },
    handleChangeTheme (command) {
      if (command == 'themeWhite') {// 清除缓存
        this.themeType = '1'
        localStorage.setItem('themeType', '1')
        location.reload()
      }
      if (command == 'themeBlack') {
        this.themeType = '2'
        localStorage.setItem('themeType', '2')
        location.reload()
      }
      this.isTheme = !this.isTheme
    },
    handleEnterFullscreen () {
      var docElm = document.documentElement;
      //W3C
      if (!this.isScreen) {
        if (docElm.requestFullscreen) {
          docElm.requestFullscreen();
          this.isScreen = true;
        }
        //FireFox
        else if (docElm.mozRequestFullScreen) {
          docElm.mozRequestFullScreen();
          this.isScreen = true;
        }
        //Chrome等
        else if (docElm.webkitRequestFullScreen) {
          docElm.webkitRequestFullScreen();
          this.isScreen = true;
        }
        //IE11
        else if (docElm.msRequestFullscreen) {
          docElm.msRequestFullscreen();
          this.isScreen = true;
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
          this.isScreen = false;
        }
        //FireFox
        else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
          this.isScreen = false;
        }
        //Chrome等
        else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
          this.isScreen = false;
        }
        //IE11
        else if (document.msExitFullscreen) {
          document.msExitFullscreen();
          this.isScreen = false;
        }

      }


    },
    handleCommand (command) {
      if (command == 'clear') {// 清除缓存
        this.$confirm('是否清除缓存？', '提示信息', {
          confirmButtonText: '是',
          cancelButtonText: '否',
          type: 'warning'
        }).then(() => {
          clearCache().then(res => {
            if (res.code == 200) {
              this.$myAlert({
                type: 'success',
                title: '清除成功'
              });
            } else {
              this.$myAlert({
                type: 'fail',
                title: res.msg
              });
            }
          });
        }).catch(() => {

        });

      }
      // 退出登录
      if (command == 'loginOut') {
        this.$store.dispatch('logout');
      }
      // 修改密码
      if (command == 'user') {
        this.isUserInfo = true;
      }
    },
    // 消息通知
    handleToNews () {
      if (this.$route.name != 'mainNews') {
        this.$router.push('/mainNews')
      }

    },
    // 关闭修改密码弹窗并清除输入框信息
    handleUserClose () {
      this.oldPassword = '';
      this.newPassword = '';
      this.affirmPassword = '';
      this.isUserInfo = false;
    },
    // 修改密码提交
    handleUserSubmit () {
      if (!this.oldPassword || this.oldPassword == '' || this.oldPassword.trim() == '') {
        this.$myAlert({
          type: 'fail',
          title: '请输入原密码'
        });
        return
      }
      if (!this.newPassword || this.newPassword == '' || this.newPassword.trim() == '') {
        this.$myAlert({
          type: 'fail',
          title: '请输入新密码'
        });
        return
      }
      if (!this.affirmPassword || this.affirmPassword == '' || this.affirmPassword.trim() == '') {
        this.$myAlert({
          type: 'fail',
          title: '请输入确认新密码'
        });
        return
      }
      if (this.newPassword != this.affirmPassword) {
        this.$myAlert({
          type: 'fail',
          title: '新密码和确认密码不一致'
        });
        return
      }
      adminProfile({
        pwd: this.oldPassword,
        newpwd: this.newPassword,
        conpwd: this.affirmPassword,
      }).then((res) => {
        if (res.code == 200) {
          this.oldPassword = '';
          this.newPassword = '';
          this.affirmPassword = '';
          this.isUserInfo = false;
          this.$store.dispatch('logout');
          this.$myAlert({
            type: 'success',
            title: res.msg
          });
        } else {
          this.$myAlert({
            type: 'fail',
            title: res.msg
          });
        }
      })

    },
  },
}
</script>


<style scoped>
@media only screen and (max-width:1000px) {
  .custom-hiden-1000-down {
    display: none !important;
  }
}

@media only screen and (max-width:550px) {
  .custom-hiden-550-down {
    display: none !important;
  }
}
</style>

<style lang="scss" scoped>
.admin {
  // height: 100vh;
  background-color: #F0F1F5;

  .el-container {
    height: 100%;
    // height: 100vh;
  }

  .main-container {
    // height: 100%;
    // padding-top: 0.5rem;
    background-color: #fff;
  }

  .main-news {
    height: 100%;
    // padding: 30px 42px;
  }

  .update {
    height: 100%;

    .wrap {
      height: 100%;
    }
  }

  .main-aside {
    height: 100%;
  }

  .header-bg {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff !important;
    padding: 0;
    padding-right: 20px;
    box-shadow: 0 2px 4px 0 #E7EDF7;
    color: #525866 !important;
    line-height: 60px !important;
    z-index: 9;

    .header-left {
      display: flex;
      align-items: center;

      .screen-img {
        width: 18px;
        height: 18px;
        margin-left: 24px;
        cursor: pointer;
      }

      i {
        font-size: 20px;
        cursor: pointer;
        margin-left: 24px;
      }
    }

    // .el-icon-back {
    // 	font-size: 0.2rem;
    // }
  }

  // .header-fixed {
  // 	position: fixed;
  // 	top: 0;
  // 	width: 100%;
  // 	z-index: 99;
  // }

  .user {
    display: flex;
    align-items: center;

    .user-new {
      position: relative;
      display: flex;
      align-items: center;
      color: #525866;
      margin-left: 26px;
      cursor: pointer;

      i {
        font-size: 15px;
        color: #525866;
      }

      .user-new-title {
        color: #525866;
        font-size: 14px;
        padding-right: 24px;
        border-right: 1px solid #E5E8F5;
        margin-left: 6px;
      }

      .user-new-img {
        width: 44px;
        height: 44px;
      }

      .red-box {
        position: absolute;
        top: 10px;
        left: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        padding: 6px;
        font-size: 8px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #fff;
        border-radius: 50%;
        background-color: #FF5219;
      }
    }
  }

  .no-data {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .aside-fixed {
    position: fixed;
    top: 0;
    padding-top: 50px;
    z-index: 98;
    box-shadow: 0 10px 18px 0 #ced1e3;
  }

  .main-wrap {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 90px 40px 32px 260px !important;
  }

  .main-wrap-bg {
    width: 20px;
    height: 100%;
    background-color: #f7f7fa;
  }

  .el-header {
    line-height: 50px;
    background-color: #000;
    color: #fff;

    .el-dropdown {
      margin-left: 16px;
      cursor: pointer;
    }

  }

  .el-aside {
    position: relative;

    box-shadow: 0 2px 4px 0 #e7edf7;
    // &::-webkit-scrollbar {
    // 	display: none;
    // }
    // padding-bottom: 24px;
  }

  .el-aside:hover {
    // overflow: auto;
    // &::-webkit-scrollbar {
    // 	width: 4px;
    // 	background-color: #ffffff;
    // 	display: block;
    // }
    // &::-webkit-scrollbar-thumb {
    // 	width: 4px;
    // 	background-color: #c8c9cc;
    // 	border-radius: 8px;
    // }
  }

  .el-main {
    // width: 12.2rem;
    // flex: 1;
    background-color: #ffffff;
    // background:linear-gradient(to left,rgba(255,255,255,1) 95%,rgba(245,246,249,1) 100%);
    // padding: 0.32rem 0.4rem;
    padding: 0;
  }

}

.logout {
  display: flex;
  align-items: center;

}

.logout-icon {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.dialog-footer {
  .el-button {
    padding: 9px 15px;
    font-size: 16px;
  }
}

.header-popup {
  .info-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;

    .info-cell-label {
      width: 100px;
      text-align: right;
      margin-right: 20px;
    }

    .password-input {
      width: 400px;
    }
  }
}

.empty-img {
  width: 282px;
  line-height: 264px;
}

.empty-text {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #525866;
  text-align: center;
  margin-top: 20px;
}

.new-item {
  padding-bottom: 16px;
  margin-bottom: 22px;
  border-bottom: 1px solid #E5E8F5;
  cursor: pointer;

  .new-item-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .new-item-title {
      font-size: 15px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #525866;
    }

    .new-item-times {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #AEB0BB;
    }
  }

  .new-item-content {
    // width: 100%;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #525866;
    line-height: 18px;
    margin-top: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .new-item-bot {
    justify-content: space-between;
  }

  .new-item-read {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #7A8599;
  }

  .readed {
    position: relative;
    padding: 10px;

    &::before {
      position: absolute;
      top: 16px;
      left: 0px;
      content: '';
      width: 6px;
      height: 6px;
      background: #E5E8F5;
      border-radius: 50%;
    }
  }

  .not-read {
    position: relative;
    padding: 10px;

    &::before {
      position: absolute;
      top: 16px;
      left: 0px;
      content: '';
      width: 6px;
      height: 6px;
      background: #FF5219;
      border-radius: 50%;
    }
  }
}

.new-box {
  display: flex;
  height: 100%;
}

.list-wrap {
  width: 610px;
  padding: 24px;
  overflow-y: auto;
  border-right: 1px solid #E5E8F5;
}

.new-detail {
  flex: 1;
  min-width: 526px;
  padding: 24px;

  .new-detail-header {
    display: flex;
    padding-bottom: 15px;
    border-bottom: 1px solid #E5E8F5;

    .new-detail-avatar {
      width: 40px;
      height: 40px;
      line-height: 40px;
      border-radius: 50%;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #6883FB;
      text-align: center;
      background-color: #ECEFFF;
      margin-right: 8px;
    }

    .new-detail-header-info {
      flex: 1;

      .new-detail-cell {
        justify-content: space-between;
      }

      .new-detail-from {
        font-size: 15px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #525866
      }

      .new-detail-time {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #7A8599;
      }

      .new-detail-to {
        align-items: center;
        margin-top: 8px;
      }

      .new-detail-to-text {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #AEB0BB;
      }

      .new-detail-to-title {
        padding: 4px 8px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #525866;
        background: #E5E8FF;
        border-radius: 2px;
      }

      .new-detail-theme {
        margin-top: 21px;
      }

      .new-detail-theme-text {
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #525866;
      }

      .new-detail-success {
        display: inline-block;
        // width: 64px;
        height: 24px;
        // line-height: 24px;
        font-size: 12px;
        text-align: center;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #44D061;
        background: #D9F7DF;
        border-radius: 2px;
        margin-top: 4px;
        padding: 2px 4px;
      }
    }
  }

  .new-detail-content {
    margin-top: 22px;

    .new-detail-content-text {
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #525866;
    }

  }
}

.new-detail-no {
  flex: 1;
  height: 100%;
  min-width: 526px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-detail-no-box {
  // height: 100%;
}

.new-no-title {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #525866;
  text-align: center;
  margin-top: 24px;
}

.new-no-img {
  width: 281px;
  height: 266px;
}

.logo-bg {
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 270px;
  height: 60px;
  // background: linear-gradient(315deg, #6872FB 0%, #6883FB 100%);
  opacity: 0.9;
  padding: 4px 10px;
  border-right: 1px solid #00000020;
}

.logo {
  max-width: 100%;
  max-height: 100%;
  cursor: pointer;
}

.query_select {
  display: flex;
  align-items: center;
  margin-left: 0.2rem;

  .query_package {
    width: 110px;
  }
}

.el-aside-nav {
  height: calc(100vh - 60px);
  overflow: hidden;
}
</style>
