<template>
  <div :class="themeType == '2' ? 'aside2 aside qjc-relative' : 'aside qjc-relative'">
    <!-- :style="{height:mainHeight + 60 + 'px'}" -->
    <div :class="themeType == '2' ? 'aside-menu theme-black-bg' : 'aside-menu'">
      <!-- <div class="logo-bg" @click="handleToHome">
				<img class="qjc-wid-100 logo qjc-hei-100" src="@/assets/images/logo-new.png" alt="logo">
			</div> -->
      <!-- :style="{height:mainHeight + 'px'}" -->
      <div class="aside-menu-list" v-if="language == 'zh'">
        <div class="aside-menu-item" v-for="(item) in $store.state.menus.filter(i => i.son && i.son.length > 0)"
          :key="item.id">
          <div :class="themeType == '2' ? 'aside-title theme-black-color' : 'aside-title'"
            @click="handleShowSon(item.id)">
            <span>{{ item.name }}</span>
            <i :class="sonShowList.indexOf(item.id) != -1 ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
          </div>
          <!-- son.icon+'-int.png' -->
          <!-- v-if="sonShowList.indexOf(item.id) != -1" -->
          <div :class="sonShowList.indexOf(item.id) != -1 ? 'son-item-list' : 'son-item-list-hide'">
            <div :class="name == son.url ? 'aside-title-son-item-active' : 'aside-title-son-item'"
              v-for="(son) in item.son" :key="son.id" @mouseover="mouseOver(item.id, son.id)"
              @mouseleave="mouseleave(item.id, son.id)" @click="handleRouter(son)">
              <div
                :class="name == son.url ? (themeType == '2' ? 'aside-title-son-active theme-black-color-active' : 'aside-title-son-active') : (themeType == '2' ? 'aside-title-son theme-black-color' : 'aside-title-son')"
                :ref="`_${son.id}`" :style="{ backgroundImage: handleMenuIcon(son) }">
                {{ son.name }}
              </div>
            </div>
          </div>

        </div>
        <div class="aside-menu-item" v-for="item in $store.state.menus.filter(i => !i.son)" :key="item.id">
          <div class="aside-title"
            :style="{ backgroundImage: 'url(' + require('@/assets/images/' + (item.icon ? item.icon + '.png' : 'data_insert.png')) + ')' }">
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="aside-menu-list" v-if="language == 'en'">
        <div class="aside-menu-item" v-for="(item) in $store.state.menus.filter(i => i.son && i.son.length > 0)"
          :key="item.id">
          <div :class="themeType == '2' ? 'aside-title theme-black-color' : 'aside-title'"
            @click="handleShowSon(item.id)">
            <div class="en_memus_t" :title="item.en_name ? item.en_name : item.name">{{ item.en_name ? item.en_name :
              item.name }}</div>
            <i :class="sonShowList.indexOf(item.id) != -1 ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
          </div>
          <div :class="sonShowList.indexOf(item.id) != -1 ? 'son-item-list' : 'son-item-list-hide'">
            <div :class="name == son.url ? 'aside-title-son-item-active' : 'aside-title-son-item'"
              v-for="(son) in item.son" :key="son.id" @mouseover="mouseOver(item.id, son.id)"
              @mouseleave="mouseleave(item.id, son.id)" @click="handleRouter(son)">
              <div
                :class="name == son.url ? (themeType == '2' ? 'aside-title-son-active theme-black-color-active' : 'aside-title-son-active') : (themeType == '2' ? 'aside-title-son theme-black-color' : 'aside-title-son')"
                :ref="`_${son.id}`" :style="{ backgroundImage: handleMenuIcon(son) }">
                {{ son.en_name ? son.en_name : son.name }}
              </div>
            </div>
          </div>

        </div>
        <div class="aside-menu-item" v-for="item in $store.state.menus.filter(i => !i.son)" :key="item.id">
          <div class="aside-title"
            :style="{ backgroundImage: 'url(' + require('@/assets/images/' + (item.icon ? item.icon + '.png' : 'data_insert.png')) + ')' }">
            {{ item.en_name ? item.en_name : item.name }}
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: "Aside",
  data () {
    return {
      name: '',
      themeType: '',
      sonShowList: [],
      mainHeight: (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 60,// 容器最小高度(去除header高度)
      language: sessionStorage.getItem('theLanguage') ? sessionStorage.getItem('theLanguage') : 'zh'
    }
  },
  // props: ['themeType'],
  mounted () {
    let type = localStorage.getItem('themeType') ? localStorage.getItem('themeType') : '1'
    this.name = this.$route.name
    this.themeType = type
  },
  watch: {
    $route () {
      this.name = this.$route.name
    },
    routerList: {
      handler (val, old) {
        const url = this.$route.path
        const routerList = val
        if (Array.isArray(routerList) && routerList.length) {
          const arr = routerList.filter(rL => rL.son && rL.son.filter(s => `/${s.url}` == url).length)
          if (arr.length) {
            this.sonShowList = [arr[0].id]
          }
        }
      },
      deep: true, // 深度监听
      immediate: true, //立即执行
    },
  },
  computed: {
    routerList () {
      const arr = this.$store.state.menus
      console.log('左侧渲染的路由', arr);
      return arr.filter(r => r.son && r.son.length)
    }
  },
  methods: {
    handleToHome () {
      if (this.$route.name != 'home') {
        this.$router.push('/')
      }
    },
    handleRouter (son) {
      let isCompany = son.url.match('adminCompany') ? true : false;
      if (isCompany && (this.$route.path != ('/' + son.url))) {
        // let list = son.url.split('/')
        // this.$router.push('/' + son.url)
        this.$router.push({
          path: '/' + son.url
        })
        this.name = son.url;

        return
      }
      if (this.$route.path != ('/' + son.url)) {
        this.$router.push('/' + son.url)
        this.name = son.url;
      }
    },
    // 鼠标移入
    mouseOver (id, sonId) {
      let menus = this.$store.state.menus;
      menus.map((item) => {
        if (item.id == id) {
          item.son.map((son) => {
            if (son.id == sonId) {
              let refId = `_${sonId}`
              let url = 'url(' + require('@/assets/images/' + (son.icon ? (this.themeType == '2' ? son.icon + '-black-int.png' : son.icon + '-int.png') : 'data_insert.png')) + ')'
              let dom = this.$refs[refId][0].style.backgroundImage = url
            }
          })
        }
      })
      // console.log(id, sonId)
    },
    // 鼠标移除
    mouseleave (id, sonId) {
      let menus = this.$store.state.menus;
      menus.map((item) => {
        if (item.id == id) {
          item.son.map((son) => {
            if (son.id == sonId) {
              let refId = `_${sonId}`
              let url = 'url(' + require('@/assets/images/' + (son.icon ? (this.themeType == '2' ? son.icon + '-black.png' : son.icon + '.png') : 'data_insert.png')) + ')'
              if (this.name != son.url) {
                let dom = this.$refs[refId][0].style.backgroundImage = url
              }
            }
          })
        }
      })
    },
    handleMenuIcon (son) {
      let background = 'url(' + require('@/assets/images/' + (son.icon ? (this.themeType == '2' ? (this.name == son.url ? son.icon + '-black-int.png' : son.icon + '-black.png') : (this.name == son.url ? son.icon + '-int.png' : son.icon + '.png')) : 'data_insert.png')) + ')'


      return background
    },
    handleShowSon (id) {


      let list = this.sonShowList;
      if (list.includes(id)) {
        this.sonShowList = []
      } else {
        this.sonShowList = [id] //只展开一个路由
      }


    }
  }
}
</script>

<style scoped>
.aside .el-menu>>>.el-menu-item.is-active {
  font-weight: 600;
}

.aside>>>.el-submenu__title {
  font-size: 18px;
  color: #777F8F;
  height: 70px;
  line-height: 70px;
}

.aside>>>.el-submenu__title:hover {
  background-color: transparent;
}

.aside-title-son-item:hover .aside-title-son {
  color: #fff;
  font-weight: 500 !important;
}
</style>
<style lang="scss" scoped>
.aside2 {
  background-color: #505C8A;
}

.aside {
  height: 100%;

  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .theme-black-bg {
    background-color: #505C8A;
  }

  .theme-black-color {
    color: #fff !important;
    font-weight: 300 !important;
  }

  .theme-black-color-active {
    color: #fff !important;
  }

  .logo-bg {
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    background: linear-gradient(315deg, #6872FB 0%, #6883FB 100%);
    opacity: 0.9;
  }

  .logo {
    width: 280px;
    height: 60px;
    cursor: pointer;
  }

  .aside-menu-list {
    width: 280px;
    padding-bottom: 50px;
    // overflow: auto;
    // height: 95%;
    // &::-webkit-scrollbar {
    // 	display: none;
    // }
  }

  .aside-menu-list:hover {
    overflow: auto;
  }

  .aside-menu {
    height: 100%;
    border: none;

    .aside-menu-item {
      // padding: 0 0.2px;
      cursor: pointer;
    }

    .son-item-list {
      display: block;
      transition: all 0.5s;
    }

    .son-item-list-hide {
      display: none;
      transition: all 0.5s;
    }

    .aside-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 54px;
      line-height: 42px;
      font-size: 18px;
      font-weight: 400;
      color: #293033;
      background-repeat: no-repeat;
      background-position: left center;
      background-size: 18px 18px;
      padding: 12px 20px 0;
    }

    .aside-title-son-item {
      padding: 0 20px;
      cursor: pointer;

      &:hover {
        color: #6883FB;
        font-weight: 500;
        background-color: rgba(203, 213, 255, 0.16);
      }
    }

    .aside-title-son-item-active {
      color: #6883FB;
      font-weight: 500;
      padding: 0 20px;
      background-color: rgba(203, 213, 255, 0.16);
      cursor: pointer;
    }

    .aside-title-son-active {
      line-height: 48px;
      font-size: 15px;
      font-weight: 500;
      color: #6883FB;
      padding-left: 26px;
      background-repeat: no-repeat;
      background-position: left center;
      background-size: 14px 14px;
    }

    .aside-title-son {
      line-height: 48px;
      font-size: 15px;
      font-weight: 400;
      color: #525866;
      padding-left: 26px;
      background-repeat: no-repeat;
      background-position: left center;
      background-size: 14px 14px;

      &:hover {
        color: #6883FB;
        font-weight: 500;
      }
    }

    .el-submenu {
      padding-left: 0 !important;

      .el-menu-item {
        padding-left: 40px !important;
        height: 70px;
        line-height: 70px;
        font-size: 15px;
        font-weight: 400;
        color: #777F8F;
      }
    }
  }

  &>div.qjc-absolute {
    left: 28px;
    bottom: 24px;
    color: #777F8F;
    line-height: 14px;

    .logout {
      cursor: pointer;

      img {
        width: 18px;
        height: 18px;
        margin-right: 12px;
        vertical-align: middle;
      }
    }

    .hospital {
      margin-top: 24px;
    }
  }
}

.en_memus_t {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
